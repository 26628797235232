body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(https://wallpapercave.com/wp/qhGPDUl.jpg);
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
input {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input {
  font-size: 2em;
  padding: 5px;
  border-radius: 10px;
  border: none;
  display: inline;
  width: calc(100% - 131px);
  background: none;
  border: 2px rgb(255, 255, 255) solid;
  margin: 7px;
}
.input:focus {
  outline: none;
}
.name {
  display: inline;
  font-size: 2em;
  margin: 5px;
}
.input-wrapper {
  padding: 5px;
  margin: 5px;
  border: 2px rgb(255, 255, 255) solid;
  border-radius: 10px;
  display: inline-block;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.buy {
  background-color: rgb(43, 43, 194);
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  font-size: 2em;
  margin: 20px;
  cursor: pointer;
}
.buy:hover {
  background-color: rgb(38, 38, 171);
}
.landing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
@media only screen and (max-width: 600px) {
  .landing {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateY(-50%) translateX(-10%);
  }
  .metamask-badge {
    display: none;
  }
}

.metamask-badge {
  position:fixed;
  bottom: 25px;
  right: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 7px;
  border-radius: 12.5px;
  background-color: rgba(220, 220, 220, 0.797);
  z-index: 10;
  font-size: 1em;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
}
.metamask-badge:hover{
  transform: scale(1.01);
}
.metamask-logo {
  height: 1.4em;
  width: 1.4em;
  vertical-align: middle;
  margin: 0px 5px;
}
.metamask-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: rgba(12, 12, 12, 0.769);
  text-decoration: none;
}